.home-update-detailpage{
    width: 95%;
    margin:20px auto;
}
.home-update-detailpage .details{
    padding: 20px ;
}
.home_update-detail_image{
    width: 100%;
}
.home_update-detail_image img{
    width: 100%;
}
.home-update-detailpage p{
    padding: 20px ;
}