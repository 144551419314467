.notfound{
    max-width: 1250px;
    margin: 0 auto;
    padding: 30px 20px;
}
.notfound h1,.notfound h2{
    font-size: 48px;
}
.notfound p{
    font-size: 18px;
    margin-bottom: 20px;
}
.notfound a{
    color: var(--white);
    background-color: var(--red);
    border: 1px solid var(--red);
    padding: 10px;
    margin-top: 30px;
}
.notfound a:hover{
    color: var(--red);
    background-color: var(--white);
}