.contact-us-main{
    max-width: 1550px;
    padding: 20px;
    margin: 0 auto;
    background-color: var(--color2);
}
.contact-us{
    width: 80%;
    margin: auto;
}
.contact-detail{
    /* display: flex; */
    justify-content: space-around;
    padding: 20px;
    background-color: var(--color1);
}
.contact-detail-item{
    /* width: 20%; */
    padding: 10px 0;
}
.contact-us-form{
    background-color: var(--color1);
    padding: 20px;
}
.ant-form-item{
    margin-bottom: 10px;
}
.ant-form-item-row{
    display: block;
}
.ant-form-item-row .ant-form-item-label{
    /* width: 100%; */
}
@media (max-width: 767px) {
    .contact-us {
        width: 100%;
    }
    .contact-detail {
        display: block;
        padding: 20px;
    }
    .contact-detail-item {
        /* width: 100%; */
        /* margin-bottom: 30px; */
    }
}


