.head-banner-main{
    max-width: 1550px;
    margin: 0 auto;
}
.react-multiple-carousel__arrow{
    z-index: 1 !important;
}
.head-banner-main .react-multi-carousel-item {
    max-width: 100% !important;
    /* width: 100% !important; */
    /* height: 72vh; */
}
.head-banner{
    width:100%;
    /* height: 72vh; */
    position: relative;
    overflow: hidden;
}
.head-banner img{
    width: 100%;
    /* height: 100%; */
}
.banner-caption{
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 35px;
    justify-content: center;
    display: flex;
}
.banner-caption p{
    font-size: 26px;
    position: relative;
    color: var(--color4);
    padding: 5px;
}
.banner-caption::before{
    content:'';
    background-color: var(--color5);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
}
@media (max-width: 1200px) {
    /* .head-banner{
        height: fit-content;
    } */
}



