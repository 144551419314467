
.about-main-page{
  max-width: 1550px;
  padding: 20px;
}
.about-main-page h1{
  text-align: center;
  color: var(--color5);
  text-transform: uppercase;
}

.about-main{
  width: 80%;
  margin: 20px auto;
  padding: 10px 0;
  background:var(--color2);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
}
.about-main .about-content{
  width: 90%;
  margin: auto;
  overflow: auto;
  text-align: justify;
}
.about-main h1,.about-main h2{
  color: var(--color5);
  text-align: center;
}
.about-main p{
  padding:5px 20px;

}
.about-main ol{
  padding: 0px 20px 10px 40px;
}
.about-main ol li{
  list-style-type: decimal;
  line-height: 1.5em;
}
.about-main ol ol li{
  list-style-type: disc;
}
.core-values-list {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 3%;
}
.about-main table{
  margin: 20px auto;
}
.about-main table caption{
  margin: 10px auto;
  caption-side: bottom;
  background-color: var(--color4);
  padding: 10px;
  color: var(--color5);
}
.about-main table, .about-main table td{
  border:2px solid var(--color5);
  border-collapse:collapse;
  padding: 5px;
}
.about-main table th{
  background-color: var(--color5);
  color: var(--color2);
  padding: 10px;
}
.about-main table td:nth-child(1){
  width: 7%;
  text-align: center;
}
.about-main table td:nth-child(2){
  width: 35%;
}
.about-main table td:nth-child(3){
  width: 35%;
}
.about-main table td:nth-child(4){
  width: 8%;
  text-align: center;
}
.about-main table td:nth-child(5){
  width: 15%;
  text-align: center;
}
/* About Board Members Card */
.member_profiles{
  display: flex;
  width: 90%;
  margin:20px auto;
}
.member_text_details{
  width: 70%;
}
.about_member_pic{
  width: 30%;
}
.about_member_pic img{
  width:100%;
}

@media (max-width: 767px) {
  .about-main{
    width:100%;
  }
  .about-main .about-content {
    width: 100%;
  }
  .member_profiles{
    display: block;
  }
  .member_text_details{
    width: 100%;
  }
  .about_member_pic{
    width: 50%;
    margin: auto;
  }
}