
.topbar{
    background-color: #013d1e;
    display: flex;
    /* justify-content: space-between; */
    justify-content: right;
    color: var(--color2);
    font-size: 13px;
    padding: 10px 20px;
    max-width: 1550px;
    margin: 0 auto;
}
.topbar-left{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.topbar-contact {
    margin:0 5px;
    padding: 0px;
    display: flex;
    align-items: center;
}
.topbar-icon{
    padding: 5px;
    background-color: var(--color4);
    border-radius: 5px;
    color: var(--color5);
    margin: 0 5px;
}
.topbar-contact span{
    padding-right: 20px;
}
.topbar-right{
    display: flex;
    align-items: center;
    font-size: 15px;
}
.topbar-right-icon{
    margin: 0 7px; 
    color: var(--color2);
}
.topbar-right-icon a:hover{
    color: var(--color4);
    transition: 0.4s;
}
nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
}
.navbar{
    max-width: 1550px;
    display: flex;
    margin: 0 auto;
    background-color: var(--color1);
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
}
.sticky-nav{
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    max-width: 1550px;
    background-color: var(--color2);
    /* z-index: 1051; */
    z-index: 10;
}
.Nav-Logo{ 
    width: 20%;

}
.Nav-Logo img{
    width: 80%;
    margin: 0 auto;
}
.bar{
    width: fit-content;
    /* margin: 0px; */
}

nav{
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
}
nav a{
    position: relative;
    color: var(--color6);
    padding: 0 10px;
    font-size: 15px;
    align-items: center;
    display: flex;
    text-transform: uppercase;
}
nav a:after{
    content: "";
    position: absolute;
    background-color: var(--color4);
    height: 3px;
    width: 0;
    left: 0;
    bottom: 5px;
    transition: 0.4s;
}
nav a:hover:after{
    width: 100%;
    transition: 0.4s;
}
nav button{
    margin-left: 15px;
}
.main-menu{
    position: relative;
    line-height: 65px;
}
.main-menu:hover .drop-down{
    display: block;
    transition: 0.4s;
}
.drop-down{
    display:none;
    position: absolute;
    top: 65px;
    left: 0;
    z-index: 20;
    width: 280px;
    text-align: left;
    background-color: var(--color4);
    font-size: 14px;
    transition: 0.4s;
}
.drop-down a{
    padding: 5px;
    color: var(--color5);
    border-bottom: 1px solid var(--color5);
    line-height: 24px;
    transition: 0.4s;
}
.drop-down a:hover{
    background-color: var(--color5);
    color: var(--color4);
    transition: 0.4s;
}
.drop-down a:after{
    bottom:5px;
    display:none;
}
/* ------Modal styling------- */
.modal .ant-modal-content{
    padding: 0 !important;
    overflow: hidden;
}
.modal .modal-head{
    background-color: var(--color5);
    padding: 10px;
    color: var(--color4);
}
.modal .ant-modal-close span{
    color: var(--color2);
}
.modal .ant-modal-close span:hover{
    color: var(--color1);
}
.modal .ant-modal-body{
    padding: 30px;
}
.ant-modal-body a{
    color: var(--color5)!important;
    background-color: var(--color4);
    font-weight: 500;
    padding: 5px;
}
.ant-modal-body a:hover{
    background-color: var(--color5);
    color: var(--color4)!important;
    /* opacity: 0.9; */
}
.modal .modal-footer{
    background-color: var(--color2);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: -30px;
}
.modal .modal-footer button{
    font-size: 15px;
}
.modal .modal-footer p:hover{
    cursor: pointer;
    color: var(--color5);
    text-decoration: underline;
}
/*-------------- menu drawer -------------*/
.menu-drawer-button{
    font-size: 26px;
}
/* .menu-drawer {
    width: 250px !important;
} */
.menu-drawer .menu-drawer-Logo{
    width: 70%;
    margin:0 auto;
}
.menu-drawer .menu-drawer-Logo img{
    width: 100%;
}
.menu-drawer .ant-drawer-body{
    background-color: var(--color5);
}
.drawer-main-menu a{
    color: var(--color1) !important;
    width: 90%;
    transition: 0.4s;
}
.menu-drawer .drawer-menu-item{
    border-bottom: 1px solid var(--color2);
}
.drawer-main-menu{
    color: var(--color1);
    text-transform: uppercase;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    transition: 0.4s;
}
.drawer-main-menu:hover,.drawer-main-menu:hover a{
    background-color: var(--color4);
    color: var(--color5) !important;
    transition: 0.4s;
}
    /* submenu drawer */
.drawer-sub-menu h4{
    font-weight: 300;
    padding: 5px 15px;
}
.drawer-sub-menu h4 a{
    color: var(--color2);
}
.drawer-sub-menu h4:hover,.drawer-sub-menu h4:hover a{
    color: var(--color5);
    background-color: var(--color2);
}
.submenu-iconup{
    transform: rotate(180deg);
    transition: 0.4s;
}
.submenu-icondown{
    transition: 0.4s;
}
@media (max-width: 1260px) {
    .main-menu{
        line-height: 40px;
    }
    nav a{
        position: relative;
        color: var(--color6);
        padding: 0 5px;
        font-size: 10px;
    }
    .drop-down{
        top: 40px;
    }
}
/* mobile responsive */
@media (max-width: 767px) {
    .topbar{
        display: block;
        font-size: 12px;
    }
    .topbar-contact {
        margin-top: 5px;
    }
    .Nav-Logo {
        width: 40%;
    }
    .sticky-nav{
        z-index: 20;
    }
    .modal .modal-footer button{
        font-size: 13px;
    }
}

