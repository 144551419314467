@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* @font-face{
  font-family:"Roboto";
  src: local("Roboto"),
 url('./assets/fonts/Roboto/Roboto-Black.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-BlackItalic.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-Bold.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-Light.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-LightItalic.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-Medium.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-MediumItalic.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-Regular.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-Thin.ttf') format("truetype"),
 url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf') format("truetype"),
} */
*{
  box-sizing: border-box;
  /* font-family: 'Roboto', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  font-family:'Roboto','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: light;
}
:root {
  --color1: #ffffff;
  --color2: #f0f0f0;
  --color3: #A6A377;
  --color4: #fbe116;
  --color5: #013d1e;
  --color6: #000;
  --transparent: transparent;
  --overlay: linear-gradient(red , black);
  --shadow: 0px 5px 5px rgba(150,150,150,.5);
  --text-shadow:0 2px 3px rgb(0 0 0 / 40%);
}
html{
  background-color: var(--color1);
}
body {
  margin: 0 auto;
  padding:0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1550px;
  margin: 0 auto;
}

h1,h2,h3,h4,h5,h6{
  margin: 0px;
  padding: 0px;
}
.theme-h2{
  font-size: 20px;
  border-bottom: 0px solid var(--color2);
  font-weight: 400;
  color: var(--color1);
  margin-bottom: 10px;
  background-color: var(--color5);
  padding: 7px;
  border-bottom: 5px solid var(--color4);
  text-transform: uppercase;
}
p,a{
  margin: 0px;
  padding: 0px;
}
a{
  text-decoration: none;
  color:  var(--color6);
}
ul,ol,
li{
  list-style-type: none;
  margin: 0;
}
textarea{
  min-height: 100px !important;
  min-width: 100% !important;
}
.ant-checkbox-group{
  display: flex !important;
  flex-wrap: wrap !important;
}
.ant-checkbox-wrapper{
  margin-left:10px !important;
}
.ant-input-disabled{
  color: var(--color5)!important;
  border-color: var(--color3) !important;
}
.ant-input-disabled:hover{
  color: var(--color5)!important;
  border-color: var(--color3) !important;
}
input,textarea,.ant-select-selector,.ant-input-affix-wrapper,.ant-picker
/* ,.ant-checkbox  */
{
  border: 1px solid var(--color5) !important;
  outline: none !important;
  box-shadow: none !important;
  display: flex ;
}
.ant-select-selector input,.ant-select-selector input:hover,
.ant-select-selector input:active,.ant-select-selector input:focus,
.ant-input-affix-wrapper input,.ant-input-affix-wrapper input:hover,
.ant-input-affix-wrapper input:active,.ant-input-affix-wrapper input:focus,
.ant-picker input,.ant-picker input:hover,.ant-picker input:active,.ant-picker input:focus
/* ,
.ant-checkbox input,.ant-checkbox input:hover,.ant-checkbox input:active,.ant-checkbox input:focus */
{
  border: none !important;
}
input:hover,input:active,input:focus,
textarea:hover,textarea:active,textarea:focus,
.ant-select-selector:hover,.ant-select-selector:active,.ant-select-selector:focus,
.ant-input-affix-wrapper:hover,.ant-input-affix-wrapper:active,.ant-input-affix-wrapper:focus,
.ant-picker:hover,.ant-picker:active,.ant-picker:focus
/* ,
.ant-checkbox:hover,.ant-checkbox:active,.ant-checkbox:focus */
{
  border: 1px solid var(--color4) !important;
  outline: none !important;
  box-shadow: none !important;
}
/* .ant-modal-wrap .ant-select-dropdown{
  z-index: 199999999999999 !important;
} */
.ant-select-item-option-selected{
  color: var(--color5) !important;
  background-color: var(--color4) !important;
}
.ant-select-item-option-active{
  color: var(--color4) !important;
  background-color: var(--color5) !important;
}
.ant-checkbox-inner {
  border-color: var(--color5)!important;
  z-index: 2;
}
.ant-checkbox-inner:after {
  border-color: var(--color4)!important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color5)!important;
  border-color: var(--color5)!important;
  /* color: var(--color4)!important; */
  z-index: 2;
}
.ant-picker-decade-btn{
  color: var(--color5) !important;
}
.ant-picker-decade-btn:hover{
  color: var(--color4) !important;
}
.ant-picker-cell-selected .ant-picker-cell-inner{
  background-color: var(--color5) !important;
  transition: 0.4s;
}
.ant-picker-cell:hover .ant-picker-cell-inner{
  background-color: var(--color4) !important;
  color: var(--color5) !important;
  transition: 0.4s;
}

button a{
  color: inherit;
}

button:disabled{
  background-color: var(--color2) !important;
  border-color: var(--color3) !important;
  color: var(--color3) !important;
  cursor: not-allowed !important;
}
.primary_btn{
  background-color: var(--color5);
  text-transform: uppercase;
  color: var(--color2);
  border: 1px solid var(--color5);
  /* height: 40px; */
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  /* max-width: 200px; */
  transition: 0.4s;
  cursor: pointer;
}
.primary_btn:hover{
  background-color: var(--color4);
  color: var(--color5);
  border: 1px solid var(--color4);
  transition: 0.4s;
}
.secondary_btn{
  background-color: var(--color1);
  text-transform: uppercase;
  color: var(--color5);
  border: 1px solid var(--color5);
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  /* height: 40px; */
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}
.secondary_btn:hover{
  background-color: var(--color2);
  /* color: var(--color2); */
  border: 1px solid var(--color3);
  transition: 0.4s;
}
.primary_btn span,.secondary_btn span{
  font-size: 14px;
}
.ribbon{
  position: relative;
  padding:0.2em 0.5em;
  font-size: 23px;
  margin: 0 0 0 -0.625em;
  line-height: 1.875em;
  color: #e6e2c8;
  border-radius: 0 0.156em 0.156em 0;
  background-color: #013d1e;
  box-shadow: -1px 2px 3px rgb(0 0 0 / 50%);
  left: 14px;
  width: fit-content;
  font-weight: 300;
  line-height: 1.25em;
  font-style: italic;
}
.ribbon:before{
  position:absolute;
  content: '';
  display: block;
  width: 0.469em;
  height: 100%;
  padding: 0 0 0.438em;
  top:0;
  left: -0.469em;
  background:inherit;
  border-radius: 0.313em 0 0 0.313em;
}


*::selection{
  background-color: #abacb0;
  color: initial;
}
::-webkit-scroll nav{
  padding: 0px;
  background-color: #cc3333;
  position: fixed;
}
::-webkit-scrollbar {
  width: 10px;
}
.merge-fields{
  display: flex;
  /* justify-content: space-between; */
  gap: 2%;
}
.input-50{
  width: 49%;
}
.input-25{
  width: 24%;
}

 /* Track  */
::-webkit-scrollbar-track {
  background:  var(--color5);
}
 /* Handle  */
::-webkit-scrollbar-thumb {
  background: var(--color3);
  
}
  /* Handle on hover  */
::-webkit-scrollbar-thumb:hover {
  background:  var(--color4);
}


/* 
.Toastify__toast-theme--dark {
  background: var(--color3) !important;
  color: var(--color1) !important;
  border: 2px solid var(--color1) !important;
}
.Toastify__progress-bar-theme--dark {
  background: var(--color2);
}
.Toastify__close-button--dark {
  color: var(--color1) !important;
  opacity: 0.5 !important;
}
.Toastify__toast--success .Toastify__toast-icon svg{
  fill: var(--color4)!important;
}
.Toastify__progress-bar--success {
  background: var(--color4) !important;
}
.Toastify__toast--info .Toastify__toast-icon svg{
  fill: var(--color2)!important;
}
.Toastify__progress-bar--info {
  background: var(--color2) !important;
}

.ant-form-item {
  margin-bottom: 5px !important;
}
.ant-input[disabled] {
  color: var(--color4) !important;
}
@media only screen and (max-width:767px) {
  .Toastify__toast-container {
    width: 70vw !important;
  }
} */
@media (max-width: 1000px) {
  .secondary_btn,.primary_btn{
    font-size: 12px;
  }
  .primary_btn span,.secondary_btn span{
    font-size: 11px;
  }
  .ribbon{
    font-size: 18px;
    left: 10px;
  }
  .input-50,.input-25{
    width: 100%;
  }
}

@media (max-width: 767px) {
  .secondary_btn,.primary_btn{
    font-size: 13px;
    padding:5px;
  }
  .primary_btn span,.secondary_btn span{
    font-size: 12px;
  }
  .ribbon{
    font-size: 16px;
  }
  .merge-fields{
    display: block;
  }
  .input-50,.input-25{
    width: 100%;
  }
}
