.listing-main{
    max-width: 1550px;
    padding: 20px;
    border-radius: 5px;
    margin: 0 auto;
}
.list-main-card .listing-note{
    padding: 10px;
    text-align: center;
    background-color: var(--color1);
    width: 50%;
    margin:20px auto;
    text-align: start;
    border-radius: 10px;
    box-shadow: var(--shadow);
}
.list-main-card .listing-note h3{
    text-align: center;
    text-transform: uppercase;
}
.list-main-card .listing-note ul li{
    list-style-type: decimal;
    padding: 5px;
}
.listing-main .list-main-card{
    width: 100%;
    margin:0 auto;
    border: 1px solid var(--color5);
    background-color: var(--color2);
    border-radius: 5px;
    position: relative;
    padding: 20px 0;
}
.list-main-card .ribbon{
    text-transform: uppercase;
    padding: 5px;
}
.listing-list{
    width: 95%;
    border: 1px solid var(--color5);
    margin: 10px auto;
}
.listing-list ol{
    padding:10px 30px;
}
.listing-list ol li{
    list-style-type: decimal;
}
.listing-list ol .select-option{
    background-color: var(--color1);
    padding: 10px ;
    margin-bottom: 15px;
}
.listing-list ol .select-option ul, .select-option p{
    padding: 10px ;
}
.listing-list ol .select-option button {
    display: flex;
    margin: auto;
}
.listing-list ol .select-option .job_requirement_detail{
    margin: 10px 0;
}
.listing-list .collective_headings{
    display: flex;
    gap: 10px;
    margin: 10px auto;
    flex-wrap: wrap;
}

.listing-list .collective_headings p{
    border: 1px solid var(--color5);
    background-color: var(--color4);
    text-transform: capitalize;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.4s;
}
.listing-list .job_details{
    display: flex;
    margin: 5px 0;
}
.listing-list .job_details span{
    margin-right: 10px;
    color: var(--color5);
}
.job_requirement_detail ul li{
    list-style-type: decimal;
    line-height: 22px;
    background-color: none;
    /* border-bottom: 1px solid var(--color5); */
}
.listing-list-item{
    padding: 5px 10px;
    border-bottom: 1px solid var(--color5);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.listing-list-buttons button{
    margin: 0 5px;
}
.listing-list-item .primary_btn{
    padding: 5px;
    height: fit-content;
}
.listing-list li button{
    font-size: 14px;
    padding: 5px;
    height: fit-content;
}
/* ------FAQ page sections----- */
.listing-list .faq-question{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid var(--color5);
    cursor: pointer;
}
.listing-list .faq-answer{
    padding: 20px;
}
.listing-list .faq-question span{
    margin: 0 10px 0 0;
    color: var(--color5);
}




@media (max-width: 767px) {
    .listing-main .list-main-card {
        width: 100%;
    }
    .listing-list ol {
        padding: 10px 25px;
    }
    .listing-list-item {
        display: block;
    }
    .news-listing{
        display: flex;
    }
    .list-main-card .listing-note{
        width: 95%;
    }
}