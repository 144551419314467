.home-main h2{
    text-align: center;
}
.home-notices-shortlists{
    max-width: 1550px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.home-notice-main{
    width: 30%;
    border: 1px solid var(--color3);
    border-radius: 10px;
    overflow: hidden;
}
.home-lists .listing-list{
    border: none;
}
.home-lists .listing-list .listing-list-item{
    border: none;
}
.home-lists li{
    list-style-type: disclosure-closed;
    padding: 5px 15px;
}
.home-lists .job_requirement_detail li:nth-child(odd),.home-lists .job_requirement_detail li:nth-child(odd){
    background-color: var(--color1);
}
.home-lists li .home-job-title{
    display: flex;
    justify-content: space-between;
}
.home-lists li:nth-child(odd){
    background-color: var(--color2);
}
.home-lists li:nth-child(even){
    background-color: var(--color1);
}
.view-all-button{
    display: flex;
    justify-content: right;
    width: 90%;
}
.view-all-button button{
    margin:10px;
}
/* -------home vacancies section------*/
.home-vacancies-main{
    max-width: 1550px;
    padding: 20px;
    background-color: var(--color2);
}
.home-vacancies{
    border: 1px solid var(--color3);
    border-radius: 10px;
    overflow: hidden;
}
/* ----- home welcome Note */
.home-welcome{
    max-width: 1550px;
    padding: 20px;
}
.welcome-main{
    display: flex;
}
.welcome-details{
    width: 70%;
    padding: 10px;
}
.chairperson-pic{
    width: 30%;
    background-color: var(--color2);
    text-align: center;
    padding: 10px;
}
.chairperson-pic img{
    width: 90%;
}
.chairperson-pic p{
    font-style: italic;
}
.welcome-details p{
    margin: 20px;
}
/* -----home update cards */
.home_updates_main{
    max-width: 1550px;
    padding: 20px;
    margin: 20px auto;
    border-bottom: 1px solid var(--color6);
}
.home-updates{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 5%;
    /* justify-content: space-between; */
}

@media (max-width: 767px){
    .home-notices-shortlists {
        display: block;
    }
    .home-updates {
        /* display: block; */
    }
    .home-notice-main {
        width: 100%;
        margin-top: 20px;
    }
    .welcome-main {
        display: block;
    }
    .welcome-details {
        width: 100%;
    }
    .chairperson-pic {
        width: 100%;
        /* margin: 0 auto; */
    }
}


@media (max-width: 500px){
    .home-updates {
        display: block;
    }
}



