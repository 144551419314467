.job-profile-page{
    max-width: 1550px;
    padding: 20px;
    margin: 0 auto;
    background-color: var(--color2);
}
.job-profile-main{
    width: 80%;
    margin: auto;
}
.job-form-detals{
    background-color: var(--color1);
    padding: 20px;
}
/* .job-form-detals .ant-select-dropdown{
    z-index: 1 !important;
  } */
.sub-heading-form{
    border: 1px solid var(--color5);
    width: fit-content;
    border-radius: 10px;
    padding-right: 10px;
}
.history-list-table{
    width: 100%;
    margin: 30px auto;
    border: 2px solid var(--color5);
    border-collapse: collapse;
}
.history-list-table td, .history-list-table th{
    border: 1px solid var(--color3);
    padding: 0 10px;
}
.table-wrap{
    overflow: auto;
    margin: 20px 0;
}

 /* Track  */
.table-wrap::-webkit-scrollbar-track {
    background:  var(--color3);
    border-radius: 10px;
}
/* Handle  */
.table-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--color4);
    
  }
    /* Handle on hover  */
.table-wrap::-webkit-scrollbar-thumb:hover {
    background:  var(--color5);
  }

.history-list-table td span{
    display: flex;
    align-items: center;
    justify-content: center;
}
.history-list-table td span:hover{
    color: var(--color3);
    cursor: pointer;
}
.record-action-btns{
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .job-profile-main{
        width: 100%;
    }
}



