.footer-main{
    background: #333;
    padding: 50px 0px;
    padding-bottom: 0px;
    color: var(--color2);
}
.footer{
    max-width: 1520px;
    padding:0 50px;
    margin: 0 auto;
    display: flex;
}
.footer-section{
    width: 30%;
    text-align: left;
}
.footer-section h2{
    padding: 10px 0;
    border-bottom: 1px solid var(--color4);
    margin-bottom: 10px ;
    text-transform: uppercase;
    color: var(--color4);
    font-size: 18px;
}
.news-letter{
    display: flex;
    align-items: center;
}
.news-letter input{
    height: 40px;
    width: 70%;
    border-color: var(--color2) !important;
    padding-left: 10px;
}
.news-letter button{
    border-color: var(--color1);
    border-radius: 0;
    height: 40px;
}
.footer-about,.footer-newsletter{
    width: 40%;
}
.footer-content{
    max-width: 290px;
    font-size: 14px;
}
.footer-links h4{
    border-bottom: 1px solid var(--color2);
    padding: 5px 0;
    text-transform: capitalize;
}
.footer-links h4 a{
    color: var(--color2);
}
.footer-content h4 a:hover{
    opacity:0.8;
}
.footer-contact-detail{
    margin-bottom: 30px;
}
.footer-icon{
    color: var(--color4);
}
.footer-twitter{
    margin-top: 60px;
}
.copy-right{
    border-top: 1px solid var(--color2);
    margin-top: 30px;
    padding: 10px;
    text-align: center;
}
.copy-right a{
    color: var(--color4);
}
@media (max-width: 767px){
    .footer{
        display:block;
        padding: 0 30px;
    }
    .footer-section {
        width: 100%;
        margin-bottom: 30px;
    }
    .footer-content{
        max-width: 100%;
    }
    .footer-links{
        display: flex;
        flex-wrap: wrap;
        gap: 20px 5%;
    }
    .footer-links h4{
        width: fit-content;
    }
    .news-letter button{
        height: 40px;
    }
    .copy-right {
        line-height: 24px;
    }
}



