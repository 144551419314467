.home_update_card {
    width: 30%;
    transition: 0.4s;
}

.home_update_card:hover {
    transform: scale(1.05);
    transition: 0.4s;
}

.home_update_image {
    position: relative;
    max-width: 100%;
}

.home_update_image::after {
    content: "";
    position: absolute;
    border: 2px solid var(--color4);
    top: 10px;
    bottom: 10px;
    right: 10px;
    left: 10px;
}

.home_update_image img {
    width: 100%;
}

.home_update_details {
    margin-top: 10px;
}

.home_update_details p {
    margin: 10px 0;
}

.home_view_button {
    margin: 20px;
    text-align: center;
}

@media (max-width: 767px) {
    .home_update_card {
        width: 47%;
        /* margin: 30px 0; */
    }
}

@media (max-width: 500px) {
    .home_update_card {
        width: 98%;
        margin: 20px 0;
    }
}